.support-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.support-page .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.support-page .table {
  margin-right: auto;
}
.support-page th,
.support-page td {
  text-align: left;
}
.support-page .split {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.support-page .split div {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
}
.support-page .split div p {
  text-align: center;
}
.support-page .split div img {
  max-width: 155px;
}
.support-page .split div .call-to-action-btn {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: 18px;
  text-decoration: none;
  color: var(--font-dark);
}
.support-page .split div .call-to-action-btn .icon-img {
  height: 24px;
  width: 24px;
  padding: 8px;
  margin-right: 18px;
  border-radius: 100%;
  background: var(--app-dark-blue);
}
.support-page .split div .call-to-action-btn .icon-img:hover {
  background: var(--app-dark-red);
}
.support-page .show-less-more {
  padding-right: 8px;
  cursor: pointer;
}
.support-page .spacer {
  flex: 1;
}
.support-page .card {
  width: 80%;
  padding: 20px;
  background: #fafafa;
}
.support-page .card .header,
.support-page .card .content {
  width: 100%;
}
.support-page .card .header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.support-page .card .terms {
  width: 80%;
  margin: 0 auto;
}
.support-page .card .terms li,
.support-page .card .terms ol {
  margin-bottom: 12px;
}

/*# sourceMappingURL=lightning-support-page.css.map */
