.fullpage-form {
  width: 350px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 45px;
}
.fullpage-form img {
  width: 150%;
}
.fullpage-form h1 {
  margin: 0;
  margin-top: 16px;
  margin-bottom: 32px;
  font-size: 20px;
  font-weight: 300;
}
.fullpage-form .segment {
  width: 260px;
  padding: 20px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: rgba(225, 232, 246, 0.42);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fullpage-form .segment > h1, .fullpage-form .segment h5 {
  padding-top: 0;
  margin: 0;
  font-weight: 200;
}
.fullpage-form .wpl-input {
  margin: 0;
  padding: 0 0 7px;
  font-size: 14px;
  font-weight: 600;
}
.fullpage-form .input-field {
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 5px 12px;
}
.fullpage-form input[type=button] {
  margin-top: 16px;
  height: 32px;
  border-radius: 4px;
  border-color: transparent;
  background: #28a02e;
  color: white;
  font-weight: 500;
  font-size: 15px;
}
.fullpage-form .error {
  color: red;
  margin: 0;
  padding: 0;
  font-size: 0.8em;
}

/*# sourceMappingURL=fullpage-form.css.map */
