.pie-chart {
  flex-basis: 350px;
  display: flex;
  flex-direction: column;
}
.pie-chart .pie-popup {
  background: #2e4e63 !important;
}
.pie-chart .pie-popup h2 {
  color: white;
}
.pie-chart .pie-svg {
  margin: 0 auto 8px;
}
.pie-chart h1, .pie-chart p {
  margin: 0;
  margin-left: 8px;
}
.pie-chart h1 {
  font-size: 20px;
}
.pie-chart p {
  margin-bottom: 26px;
  font-size: 14px;
}

.pie-chart:nth-of-type(2) {
  margin-left: 8px;
  margin-right: 8px;
}

/*# sourceMappingURL=defect-pie.css.map */
