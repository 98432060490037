.dm-cornis h3 {
  font-weight: normal;
}
.dm-cornis .turbine-data {
  display: flex;
  justify-content: space-between;
}
.dm-cornis .turbine-data .blades {
  width: 40%;
  padding: 8px;
  margin-left: 32px;
  border: 1px solid black;
  cursor: auto;
}
.dm-cornis .turbine-data .blades p span {
  margin-right: 8px;
}
.dm-cornis .turbine-data .blades h4 {
  margin: 4px 0 0;
}

/*# sourceMappingURL=dm-cornis.css.map */
