.wpl-form {
  width: 80%;
  margin: 0 auto;
  max-height: 80%;
  padding-bottom: 30px;
}
.wpl-form .wpl-input {
  width: 100%;
}
.wpl-form .wpl-button {
  height: 35px;
  margin-top: 25px;
  width: 100%;
}

/*# sourceMappingURL=wpl-form.css.map */
