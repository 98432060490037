.simple-defect-view {
  width: 100%;
  padding-bottom: 4px;
}
.simple-defect-view .wpl-table {
  padding-left: 4px;
}
.simple-defect-view h1 {
  font-size: 20px;
  padding-top: 8px;
}
.simple-defect-view h1, .simple-defect-view p {
  padding-left: 8px;
  margin: 0;
}
.simple-defect-view p {
  padding-top: 4px;
  padding-bottom: 12px;
}

/*# sourceMappingURL=total-defect-overview.css.map */
