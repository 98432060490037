.blade-defect-table {
  width: calc(100% - 8px);
}
.blade-defect-table h1 {
  margin-left: 8px;
}
.blade-defect-table .blade-table {
  width: calc(100% - 8px);
  margin-left: 8px;
}

/*# sourceMappingURL=blade-defect-table.css.map */
