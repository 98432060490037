.folder {
  margin-left: 16px;
  cursor: pointer;
}
.folder p {
  margin-left: 16px;
}
.folder h4:hover {
  text-decoration: underline;
}

/*# sourceMappingURL=folder.css.map */
