.dm-segmentation .unet-inp-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.dm-segmentation .unet-inp-wrapper .wpl-input {
  margin-right: 20px;
}
.dm-segmentation .unet-inp-wrapper .wpl-button {
  height: 30px;
}

/*# sourceMappingURL=dm-segmentation.css.map */
