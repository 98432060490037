.severity-list {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  height: 49px;
}
.severity-list div {
  cursor: pointer;
  line-height: 49px;
  text-align: right;
  flex: 1;
  padding-right: 20px;
}
.severity-list div:hover {
  font-weight: 600;
}

.severity-list.small {
  width: 100%;
  height: 31px;
}
.severity-list.small div {
  line-height: 31px;
}

/*# sourceMappingURL=lightning-probability-list.css.map */
